import React from 'react'
import PropTypes from "prop-types";
import {connect} from "react-redux";
import * as Cookies from "js-cookie";
import UserHeader from '../Parts/Header/Header-User';
import GuestHeader from '../Parts/Header/Header-Guest';
import socket from "../../../Socket";
import {Event, decode, encode, wait, isMobile} from "../../../Helper";
import WalletAlert from "../../../App/Components/User/Wallet/Alert";
import C from "../../../Constant";
import Login from "../Auth/Login";
import storage from "../../../Storage";
import Engine from "../../../App/Games/Crash/Engine";
import Mobi from "../../../App/Games/Crash/Graphic/Mobi";
import { useParams } from 'react-router-dom';
import {Link} from "react-router-dom";

class Game extends React.Component {
    _Mounted = false;
    constructor(props){
        super(props);
        this.state = {
            mobile:false,
            isLogged: false,
            token: false,
            header: <GuestHeader t={this.props.t} location={this.props.location} />,
            auth: false,
        }
        this.handleResize = this.handleResize.bind(this);
    }
    componentWillMount() {
        wait(500).then(() => {
            this._Mounted = true;
        })
    }

    componentDidMount(){
        socket.on(C.ONLINE, (status) => this.loginUser(decode(status)));
        Event.on('showAuthModal', (status) => this.activeAuth(status));
        let path = window.location.pathname;
        let game_variant = path.replace('/game-launch/','');
        socket.emit(C.STP_GAME, encode({
            game_variant: game_variant
        }))
        socket.on(C.STP_GAME, (games) => this.loadGames(decode(games)));
        console.log("game id: " + game_variant)
        /**
         * Initialize Authentication
         */
        const sessionCookie = Cookies.get("auth");

        if(storage.getKey('token') !== null && sessionCookie)
        {
            socket.emit(C.ONLINE, encode({
                jwt_token: storage.getKey('jwt_token'), //fake
                user_token: storage.getKey('user_token'), //fake
                security_key: storage.getKey('security_key'), //fake
                secret_user: storage.getKey('secret_user'), //fake
                secret_realtime: storage.getKey('secret_realtime'), //fake
                client_system: storage.getKey('client_system'), //fake
                token_key: storage.getKey('token_key'), //fake
                secret_token: storage.getKey('secret_token'), //fake
                token: storage.getKey('token'), // REAL
            }));
        }
        wait(600).then(() => {
            this.handleResize();
        });
        window.addEventListener('resize', this.handleResize);

        if(storage.getKey('token')){
            this.setState({ isLogged: true, token: storage.getKey('token') });
        }

        wait(500).then(() => {
            Engine.getStatus();
        });
    }

    activeAuth = (status) => {
        this.setState({ auth: status });
    }

    loadGames = (data) => {
        this.setState({stp_game: data.game})
        const url = data.game.url.replace('{token}', storage.getKey('user_token'))
        this.setState({game_url: url})
        return data.game

    }

    loginUser = (data) => {
        wait(500).then(() => {
            // console.log('Log in user data', data)
            if(data.status === true)
            {
                this.setState({ header: <UserHeader t={this.props.t} location={this.props.location} /> });
                Cookies.set("uid", data.id, {expires: 14});
                Cookies.set("auth", true, {expires: 14});
                // storage.setKey('name', data.name);
                // storage.setKey('email', data.email);
                // storage.setKey('phoneNumber', data.phoneNumber);
                // storage.setKey('credit', data.credit);
                // storage.setKey('avatar', data.avatar);
                // storage.setKey('friends', data.friends);
                // storage.setKey('room', data.room);
            }
            else {
                wait(7000).then(() => {
                    localStorage.clear();
                })
            }
        })
    }
    getWidth(){
        return document.documentElement.clientWidth || document.body.clientWidth;
    }
    handleResize(){
        if(this.getWidth() < 1540)
        {
            this.setState({ col: 'col-xl-12'});
            Event.emit('hide_games');
        }
        else {
            this.setState({ col: 'col-xl-9'});
            Event.emit('show_min_games');
        }

        if(isMobile()){

            this.setState({ mobile: true });
        }
        else {
            this.setState({ mobile: false });
        }

        if(isMobile()){
            this.setState({ padding: 'p-0', ovh: 'ovh' });
        }
    }
     render(){
        let { header, auth, mobile, games, stp_game } = this.state;
        let { content } = this.props; // Pages / Game Contents
        let wallet; // Show Wallet if User don't Have Cash
        let { game } = this.props;
        try {
            wallet = this.props.get_wallet.show;
        }
        catch (e) {}

        const engine = Engine;

        const { t } = this.props;
        // const stp = await this.loadGames()
        console.log("render: "+ JSON.stringify(stp_game))
        // const list = stp_game?.map((row, i) =>
        //     <List key={i} row={row} />
        // );
         if(stp_game){


        return(
            <>

                <div className="page-wrapper">

                    <div className="page-content container py-1 px-1" id={'page-content'}>
                        {header}

                        <div>
                            {wallet &&
                                <WalletAlert t={t} uid={this.props.get_wallet.uid} />
                            }
                            {auth === true &&
                                <Login t={t} justModal="true" />
                            }
                        </div>
                        <iframe src={this.state.game_url}></iframe>
                        
                        {/*<>*/}
                        {/*    {list?.length === 0 ?*/}
                        {/*        <>*/}
                        {/*            You have not introduced anyone yet*/}
                        {/*        </>*/}
                        {/*        :*/}
                        {/*        <div className={'games-section sss'}>*/}
                        {/*            {list}*/}
                        {/*        </div>*/}

                        {/*    }*/}
                        {/*</>*/}
                        {/*<iframe src={stp_game} >*/}

                        {/*</iframe>*/}



                        <div className='row footer-card m-0 p-0 mt-1 text-lightt justify-content-center'>


                            <div className='col-md-8 mb-2'>
                                <div className=' '>

                                    Must be 18+ to register or play. Please gamble responsibly. Licensed and authorised
                                    by BCLB under license No. 0000619 <br/>



                                </div>
                                <div className={'text-center'}>
                                    <img src="/assets/images/years.png" className={'years'}/> <br/>
                                </div>
                                <div className={'text-center'}>
                                    <Link to={'/responsible-gambling'}>
                                        <span className={"dropdown-item responsible-gambling"}>
                                            Responsible Gambling
                                        </span>
                                    </Link>
                                </div>
                            </div>

                        </div>
                    </div>


                </div>
            </>
        );

         }else{
             return (<></>)
         }
    }
}

class List extends React.Component {

    render(){
        let { row } = this.props;
        return (
            <div className={'game-card'}>
                <span >
                </span>
            </div>
        );
    }
}

Game.propTypes = {
    get_wallet: PropTypes.string
};

const mapStateToProps = state => ({
    get_wallet: state.items.get_wallet
});

export default connect(mapStateToProps, null)(Game);